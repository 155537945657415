<template>
  <div>
    <hr v-if="!spinner"/>
    <b-spinner v-if="spinner" variant="primary" class="spinners"/>
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col cols="12">
          <b-card header-bg-variant="cardTitle" header-tag="header">
            <template #header>
              <h4>Kullanıcı Listesi</h4>
            </template>
            <table class="table table-striped mt-1 table-hover table-sm" :key="count">
              <thead>
              <tr>
                <th scope="col" width="30%">KULLANICI ADI</th>
                <th scope="col">HESAP SAHİBİ</th>
                <th scope="col">TELEFON</th>
                <th scope="col">YETKİ TÜRÜ</th>
                <th scope="col">DURUM</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(user, index) in $store.getters.kurumUserList || userList" :key="index" class="fw-bold">
                <td width="30%">
                  <b-row class="my-rows">
                    <b-col cols="4" v-if="user.userName">
                      <b-avatar variant="danger" class="pull-up" :text="user.userName.slice(0, 2)"/>
                    </b-col>
                    <b-col cols="8" class="d-flex justify-content-between">
                      {{ user.userName }}
                      <b-badge v-if="user.admin == 1" variant="bgSuccess"> Sistem Yöneticisi</b-badge>
                      <b-badge :variant="user.pasif == 1 ? 'warning' : 'primary'">
                        {{ user.pasif == 1 ? 'Pasif' : 'Aktif' }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </td>
                <td>{{ user.isim }}</td>
                <td>{{ user.tel }}</td>
                <td>
                  <b-badge class="userP text-dark" :variant="
                    user.level == 1
                      ? 'bgInfo'
                      : user.level == 2
                        ? 'bgWarning'
                        : 'bgDanger'
                  "
                  >
                    {{
                      user.level == 1
                          ? 'Yönetici'
                          : user.level == 2
                              ? 'Kullanıcı'
                              : ''
                    }}
                  </b-badge>
                </td>
                <td>
                  <b-form-checkbox checked="true" class="custom-control-primary" name="check-button" switch
                                   @change="onlineSet(user.userID)" :value="user.pasif == 1 ? false : true" disabled
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon"/>
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon"/>
                    </span>
                  </b-form-checkbox>
                </td>
                <!--                <td>-->
                <!--                  <b-button variant="bgWarning" class="btn-icon rounded-circle mr-1 text-white" size="sm"-->
                <!--                            @click="readSifreUpdate(user)" v-b-modal.pwd-change-->
                <!--                  >-->
                <!--                    <feather-icon icon="LockIcon"/>-->
                <!--                  </b-button>-->
                <!--                  <b-button variant="info" class="btn-icon rounded-circle mr-1" size="sm" @click="odaGuncelle(user)">-->
                <!--                    <feather-icon icon="EditIcon"/>-->
                <!--                  </b-button>-->
                <!--                  <b-button variant="danger" class="btn-icon rounded-circle" size="sm" @click="odaRemove(user)">-->
                <!--                    <feather-icon icon="TrashIcon"/>-->
                <!--                  </b-button>-->
                <!--                </td>-->
              </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
        <!--        <b-col cols="4">-->
        <!--          <b-card header-bg-variant="cardTitle" header-tag="header">-->
        <!--            <template #header>-->
        <!--              <h4>-->
        <!--                {{-->
        <!--                  isEdit == false-->
        <!--                      ? 'Kullanıcı Kaydı'-->
        <!--                      : kullaniciAdi + ' İsimli Kullanıcı Düzenleniyor'-->
        <!--                }}-->
        <!--              </h4>-->
        <!--            </template>-->
        <!--            <b-form-group label="Kullanıcı Türü" label-for="basicInput" class="mt-1">-->
        <!--              <v-select :components="{ OpenIndicator, Deselect }" v-model="tur"-->
        <!--                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"-->
        <!--                        :options="turOption"-->
        <!--              />-->
        <!--            </b-form-group>-->
        <!--            <b-form-group label="Kullanıcı Adı" label-for="basicInput" class="mt-1">-->
        <!--              <b-form-input id="basicInput" v-model="kullaniciAdi" placeholder="Kullanıcı Adı" :disabled="isEdit"/>-->
        <!--            </b-form-group>-->
        <!--            <b-form-group v-if="!isEdit" label="Şifre" label-for="basicInput" class="mt-1">-->
        <!--              <b-form-input id="basicInput" v-model="sifre" placeholder="Kullanıcı Şifresi"/>-->
        <!--            </b-form-group>-->
        <!--            <b-form-group label="Personel Adı/Soyadı" label-for="basicInput" class="mt-1">-->
        <!--              <b-form-input id="basicInput" v-model="name" placeholder="Personel Adı / Soyadı"/>-->
        <!--            </b-form-group>-->
        <!--            <b-form-group label="Telefon">-->
        <!--              <b-form-input id="basicInput" v-model="tel" placeholder="Telefon"/>-->
        <!--            </b-form-group>-->
        <!--            <b-form-group label="E-Posta" label-for="basicInput" class="mt-1">-->
        <!--              <b-form-input id="basicInput" v-model="mail" placeholder="E-Posta"-->
        <!--                            @keyup.enter="isEdit == false ? odaSave() : odaUpdate()"-->
        <!--              />-->
        <!--            </b-form-group>-->
        <!--            <b-row class="odaKayitRow">-->
        <!--              <b-button v-show="isEdit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mt-1 mr-1"-->
        <!--                        @click="editClosed"-->
        <!--              >-->
        <!--                Vazgeç-->
        <!--              </b-button>-->
        <!--              <b-button v-show="isEdit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="mt-1 mr-1"-->
        <!--                        @click="odaUpdate"-->
        <!--              >-->
        <!--                Kaydet-->
        <!--              </b-button>-->
        <!--              <b-button v-show="!isEdit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="mt-1 mr-1"-->
        <!--                        @click="odaSave"-->
        <!--              >-->
        <!--                Kaydet-->
        <!--              </b-button>-->
        <!--            </b-row>-->
        <!--            <b-row :class="{ editOnPrgoress: isEdit }"></b-row>-->
        <!--          </b-card>-->
        <!--        </b-col>-->
      </b-row>
    </div>
    <b-modal id="pwd-change" cancel-variant="secondary" ok-title="Accept" ok-only hide-footer centered size="xs"
             :title="`Şifre Değiştir (${userName})`"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Yeni Şifre">
            <b-form-input id="basicInput" v-model="newPwd" @keyup.enter="pwdUpdate()"/>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="my-modal-col">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" @click="pwdUpdate">
            Güncelle
          </b-button>
          <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-bgDanger"
                    @click="$bvModal.hide('pwd-change')"
          >
            İptal
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
  VBModal,
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile, BSpinner,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    VBModal,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      userList: [],
      spinner: true,
      Deselect: {
        render: (createElement) => createElement('span', ''),
      },
      OpenIndicator: {
        render: (createElement) => createElement('span', ''),
      },
      isEdit: false,
      userName: '',
      userID: '',
      newPwd: '',
      chooseUserID: '',
      count: 0,
      kullaniciAdi: '',
      sifre: '',
      name: '',
      tel: '',
      mail: '',
      tur: {
        title: 'Personel',
        key: 2
      },
      turOption: [
        {
          title: 'Yönetici',
          key: 1
        },
        {
          title: 'Personel',
          key: 2
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    pwdUpdate() {
      if (this.newPwd) {
        this.$store
            .dispatch('userPwdChange', {
              userID: this.userID,
              sifreDegis: this.newPwd,
            })
            .then((res, position = localStorage.getItem('successReturn')) => {
              this.$bvModal.hide('pwd-change'),
                  (this.newPwd = ''),
                  (this.userName = ''),
                  (this.userID = '')
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            })
            .catch((err) => {
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            })
        this.count = this.count + 1
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Şifre Boş Geçilemez',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    odaSave() {
      this.$store
          .dispatch('newUser', {
            tur: this.tur.key,
            user: this.kullaniciAdi,
            pwd: this.sifre,
            isim: this.name,
            tel: this.tel,
            ePosta: this.mail,
          })
          .then((res, position = localStorage.getItem('successReturn')) => {
            (this.tur = {
              title: 'Personel',
              key: 2
            }),
                (this.odaKodu = ''),
                (this.kullaniciAdi = ''),
                (this.sifre = ''),
                (this.name = ''),
                (this.tel = ''),
                (this.mail = '')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch((err) => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    odaUpdate() {
      if (this.isEdit) {
        this.$store
            .dispatch('userUpdate', {
              tur: this.tur.key,
              isim: this.name,
              tel: this.tel,
              ePosta: this.mail,
              userID: this.chooseUserID,
            })
            .then((res, position = localStorage.getItem('successReturn')) => {
              this.isEdit = false;
              (this.tur = {
                title: 'Personel',
                key: 2
              }),
                  (this.odaKodu = ''),
                  (this.kullaniciAdi = ''),
                  (this.sifre = ''),
                  (this.name = ''),
                  (this.tel = ''),
                  (this.mail = '')
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            })
            .catch((err) => {
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Oda Güncelleme Başarısız',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    onlineSet(id) {
      this.$store.dispatch('userStatusChange', {
        id: id,
      })
          .then((res, position = localStorage.getItem('successReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch((err) => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
      this.count = this.count + 1
    },
    odaGuncelle(item) {
      this.isEdit = true
      this.chooseUserID = item.userID
      this.kullaniciAdi = item.user
      this.name = item.isim
      this.tel = item.tel
      this.mail = item.ePosta
      this.tur =
          item.level == 1
              ? {
                title: 'Yönetici',
                key: 1
              }
              : {
                title: 'Personel',
                key: 2
              }
    },
    readSifreUpdate(item) {
      this.userID = item.userID
      this.userName = item.user
    },
    editClosed() {
      this.isEdit = false;
      (this.chooseUserID = ''),
          (this.tur = {
            title: 'Personel',
            key: 2
          }),
          (this.odaKodu = ''),
          (this.kullaniciAdi = ''),
          (this.sifre = ''),
          (this.name = ''),
          (this.tel = ''),
          (this.mail = '')
    },
    odaRemove(val) {
      if (val.userID == this.chooseUserID) {
        (this.isEdit = false),
            (this.tur = {
              title: 'Personel',
              key: 2
            }),
            (this.odaKodu = ''),
            (this.kullaniciAdi = ''),
            (this.sifre = ''),
            (this.name = ''),
            (this.tel = ''),
            (this.mail = ''),
            this.$store.dispatch('userSil', { id: val.userID })
                .then((res, position = localStorage.getItem('successReturn')) => {
                  const mesaj = this.$store.getters.notificationSettings(res)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch((err) => {
                  const mesaj = this.$store.getters.notificationSettings(err)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  }, { position })
                })
      } else {
        this.$store
            .dispatch('userSil', { id: val.userID })
            .then((res) => {
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              })
            })
            .catch((err) => {
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            })
      }
    },
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 200)
    this.$store.dispatch('userList')
        .then(res => {
          this.userList = res[0]
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
}
</script>

<style scoped>
.my-modal-col {
  justify-content: space-around;
  display: flex;
}

.fw-bold {
  font-weight: 900;
}

.my-rows {
  align-items: center !important;
}

.badge {
  padding: 0.3rem;
  border-radius: 15px;
  margin-top: 0.1rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  text-align: start;
}

@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
  /* animation-iteration-count: 1 !important; */
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td[data-v-199ebc45][data-v-199ebc45] {
  text-align: center;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #31526f;
  color: white;
  padding: 0.6rem;
  border: none;
  text-align: center;
}

[dir] .table th[data-v-199ebc45],
[dir] .table td[data-v-199ebc45] {
  padding: 0.1rem;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #0ea9d7;
  color: white;
}

.userP {
  padding: 5px;
}
</style>
<style>

[dir] .table th[data-v-247cb7f1],
[dir] .table td[data-v-247cb7f1] {
  padding: 0.2rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
  text-align: center;
}

.pull-up {
  transition: 200ms;
}

th {
  text-align: left !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
